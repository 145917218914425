import { useContext } from "react";
import { ContentContext } from "../../Context";
import { useHistory } from "react-router-dom";

import Snowflakes from "../../components/Snowflakes/Snowflakes";

import Logos from "../../data/HomePage_logos";

function HomePage() {
  const { homePageDatas } = useContext(ContentContext);

  const { headingText, buttonText, firstText, secondText, thirdText } = homePageDatas;
  const { copyright_bm_logo, snallkalendern_logo_green, friends_logo, tietoevry_logo, mrs_mighetto_logo } = Logos;

  const history = useHistory();

  const handleUrl = () => {
    history.push("/calendar");
  };

  return (
    <div className="homePage">
      <Snowflakes />
      <div className="homePage-container">
        <div className="homePage-content">
          <img className="homePage-snallkalendern" src={snallkalendern_logo_green} alt="snallkalendern" />
          <h2>{headingText}</h2>
          <button className="homePage-button" onClick={handleUrl}>
            <p>{buttonText}</p>
          </button>
          <div className="homePage-text">
            <p>{firstText}</p>
            <p>{secondText}</p>
            <p>{thirdText}</p>
          </div>
        </div>
      </div>
      <footer>
        <div className="footer-items" >
          <img className="copy-right-bm-style" src={copyright_bm_logo} alt="bm copyright" />
          <img className="logo-style" src={friends_logo} alt="friends logo" />
          <div className="column-line"></div>
          <img className="logo-style" src={tietoevry_logo} alt="tietoevry logo" />
          <div className="column-line"></div>
          <img className="logo-style mrs-mighetto-style" src={mrs_mighetto_logo} alt="mrs mighetto logo" />
        </div>
      </footer>
    </div>
  );
}

export default HomePage;
