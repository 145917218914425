import React from "react";

function CalendarCard({ onClick, date, cardWidth, cardMarginTop, cardMarginLeft, cardHeight }) {
  return (
    <div className="card" style={{ width: cardWidth, height: cardHeight, marginTop: cardMarginTop, marginLeft: cardMarginLeft }} onClick={onClick}>
      <h3>{date}</h3>
    </div>
  );
}

export default CalendarCard;
