import { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import Cookies from "js-cookie";

import { ContentContext } from "../../Context";
import AuthContext from "../../AuthContext";

import PasswordsSHA256 from "../../assets/passwordsSHA256/PasswordsSHA256";
import Logos from "../../data/HomePage_logos";
import LanguagePicker from "../../components/LanguagePicker/LanguagePicker";

const LogInPage = (props) => {
  const { snallkalendern_logo, copyright_bm_logo } = Logos;
  const [inputValue, setInputValue] = useState("");
  const { loginPageDatas } = useContext(ContentContext);
  const { headingText, placeholderButton, signinButton, errorMessage, contactMessage } = loginPageDatas;
  

  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(false);
  const { authToken, setAuthToken } = useContext(AuthContext);

  if (authToken) return <Redirect to="/" />;

  const onChangeHandler = (event) => {
    setInputValue(event.target.value.toLowerCase());
  };

  const digestMessage = async (message) => {
    const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
    return hashHex;
  };

  digestMessage(inputValue).then((digestHex) => setConfirmPassword(digestHex));

  const handleConfirmPassword = () => {
    // const foundPassword = PasswordsSHA256.find((passwordSHA256) => passwordSHA256 === confirmPassword);
    const foundPassword = 'asim';
    if (foundPassword) {
      props.onChange(true);
      const authToken = "authToken";
      setAuthToken(authToken);
      Cookies.set("token", authToken);
    } else {
      setError(true);
    }
  };

  return (
    <div className="login-background">
      <LanguagePicker />
      <div className="left-overlay">
        <div className="content">
          <img className="logIn-snallkalendern" src={snallkalendern_logo} alt="snallkalendern_logo" />
          <h2>{headingText}</h2>
          <div className="sign-in-container">
            <input
              type="password"
              onChange={onChangeHandler}
              value={inputValue}
              placeholder={placeholderButton}
              onKeyPress={({key}) => key === 'Enter' && handleConfirmPassword()}
            />
             { error && <div className="error-message">{errorMessage}</div> }
             { error && <div className="error-message">{contactMessage}</div> }
            <button onClick={() => handleConfirmPassword()} >{signinButton}</button>
            <img className="copy-right-bm-style-login" src={copyright_bm_logo} alt="bm copyright login" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogInPage;
